<template>
  <div>
     <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="mini" style="width: 100%" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option v-for="item in orderStatusMap" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="包装类型">
          <el-select class="item-choose" v-model="dataForm.packType" size="mini" @change="getDataList()" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="请输入客户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDataList"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="卸货地址">
          <el-input v-model="dataForm.giveAddress" placeholder="卸货地址" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table :data="dataList"
                ref="tableRef"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                height="59vh"
                border
                size="small"
                :span-method="arraySpanMethod"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
        >
          <template slot-scope="scope">
            <!-- 提货时间 -->
            <template v-if="item.prop == 'orderInfo.takeGoodsDate'">
              {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
            </template>
            <!-- 货物尺寸(长/宽/高) -->
            <template v-else-if="item.prop == 'orderGoods.length'">
              {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
            </template>
            <!-- 状态 -->
            <template v-else-if="item.prop == 'status'">
              <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="left" align="center" width="55" label="操作">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="detail(scope.row, 'view')">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from "@/components/table";
import { newAuth } from '@/utils'
import qs from 'qs'
import * as myJsonClon from "@/utils/myJsonClone";

export default {
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 5,
        configDefaultColumn: [
          {label: '订单号',prop: 'orderInfo.orderNo',width: '105"',check: true,fixed: undefined},
          {label: '客户名称',prop: 'orderInfo.customerName',width: '70',check: true,fixed: undefined},
          {label: '提货时间',prop: 'orderInfo.takeGoodsDate',width: '105',check: true,fixed: undefined},
          {label: '订单类型',prop: 'orderInfo.orderType',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '所需车型',prop: 'orderInfo.vehicleType',width: '80',formatter: 'dictConvertVehicle',check: true,fixed: undefined},
          {label: '报关方式',prop: 'orderInfo.entranceType',width: '80',formatter: 'entranceType',check: true,fixed: undefined},
          {label: '提货方',prop: 'factoryName',width: '75',check: true,fixed: undefined},
          {label: '提货地址',prop: 'fullAddress',width: '180',check: true,fixed: undefined},
          {label: '提货联系人',prop: 'contactName',width: '70',check: true,fixed: undefined},
          {label: '提货联系人电话',prop: 'contactPhone',width: '85',check: true,fixed: undefined},
          {label: '提货注意事项',prop: 'takeRemark',width: '85',check: true,fixed: undefined},
          {label: '卸货方',prop: 'giveAddressInfo.factoryName',width: '75"',check: true,fixed: undefined},
          {label: '收货地址',prop: 'giveAddressInfo.fullAddress',width: '180"',check: true,fixed: undefined},
          {label: '收货联系人',prop: 'giveAddressInfo.contactName',width: '70"',check: true,fixed: undefined},
          {label: '收货电话',prop: 'giveAddressInfo.contactPhone',width: '90"',check: true,fixed: undefined},
          {label: '件数',prop: 'orderGoods.pieceNum',width: '40"',check: true,fixed: undefined},
          {label: '体积',prop: 'orderGoods.volume',width: '40"',check: true,fixed: undefined},
          {label: '重量',prop: 'orderGoods.weight',width: '40"',check: true,fixed: undefined},
          {label: '货物尺寸(长/宽/高)',prop: 'orderGoods.length',width: '110"',check: true,fixed: undefined},
          {label: '包装类型',prop: 'orderGoods.packType',width: '80',formatter: 'manyDictConvert',check: true,fixed: undefined},
          {label: '客户单号',prop: 'orderInfo.customerSelfNo',width: '70"',check: true,fixed: undefined},
          {label: '下单时间',prop: 'orderInfo.commitTime',width: '120"',check: true,fixed: undefined},
          {label: '内部备注',prop: 'orderInfo.companyRemark',width: '100"',check: true,fixed: undefined},
          {label: '状态',prop: 'status',fixed: 'right',width: '50"',check: true},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        xorderType: null,
        giveAddress: null,
        takeGoodsTime: null,
        packType: null,
        customerName: null,
        status: null,
        vehiclePlate: null,
        customerIdList: []
      },
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      listType: 3,
      getIndex: -1,
      orderStatusMap: [
        { id: 1, name: '待审核' },
        { id: 2, name: '调度中' },
        { id: 3, name: '已调度' },
        { id: 4, name: '待出车' },
        { id: 5, name: '已出车' },
        { id: 6, name: '已到达' },
        { id: 7, name: '已装货' },
        { id: 15, name: '卸货到场' },
        { id: 8, name: '已签收' },
        { id: 9, name: '异常结束' },
        { id: 11, name: '订单退回' }
      ]
    }
  },
  components: {
    configTable
  },
  created () {
    this.initConfigTable()

    // this.setDefaultDate()
  },
  activated () {
    if (!newAuth('order:manager:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    // this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {this.config.configDefaultPropColumn.push({prop: x.prop})})
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    // -----------------动态表格模式end-----------------

    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'orderType': this.dataForm.orderType,
        'giveAddress': this.dataForm.giveAddress,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'packType': this.dataForm.packType,
        'status': this.dataForm.status,
        'customerName': this.dataForm.customerName,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findAllOrderList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.$emit('getTotal')
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo
        }
      })
    }
    // // 新增 / 修改
    // addOrUpdateHandle (id) {
    //   this.addOrUpdateVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.addOrUpdate.init(id)
    //   })
    // }
  }
}
</script>
